@import '../index.scss';

.saveModal {
  padding: 0 18px 20px 18px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  svg {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .title {
    color: var(--grey-90);
    font-weight: 700;
    text-align: center;
  }

  .description {
    font-size: 16px;
    color: var(--grey-70);
    font-weight: 400;
    text-align: center;

    a {
      text-decoration: none;
      font-weight: 600;
      color: var(--prim-100);
    }
  }
}
