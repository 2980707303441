.addBar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 8px;

  .addButton {
    border: 1px solid rgba(218, 220, 226, 0.81);

    &:hover {
      background-color: #e2626e;
      color: white;
      border-radius: 12px;

      svg, rect, path {
        fill: white;
      }
    }
  }
}
