.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #e2626e;
    color: white;
    border-radius: 12px;

    svg, rect, path {
      fill: white;
    }
  }
}
