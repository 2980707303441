.drawer {

  .drawerHeader {
    display: flex;
    align-items: center;
    padding: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;

    color: #292C31;

    .drawerClose {
      cursor: pointer;
      width: 22px;
      height: 22px;
      border: 1px solid #BDC2CE;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 9px;

      .drawerCloseIcon {
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);

        &.close {
          transform: rotate(180deg);
        }

        &.open {
          transform: rotate(0deg);
        }
      }
    }
  }

  .drawerContent {
    transition: max-height 0.3s ease-in-out;

    overflow-y: auto;
    margin-bottom: 12px;

    &.open {
      max-height: 300px;
    }

    &.closed {
      max-height: 0;
    }
  }
}
