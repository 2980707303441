@import '../../index.scss';

.drawer {
    white-space: nowrap;
    overflow-x: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 256px;
    z-index: 1000;

    @include respond-to('small') {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.93);

        .drawerContent {
            .drawerBody {
                font-size: 20px;
                align-items: center;
            }
        }
    }

    transition: transform 0.3s ease-in-out;

    &.open {
        transform: translateX(0);
    }

    &.closed {
        transform: translateX(-100%);
    }

    .drawerContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        .drawerHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            height: 64px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .drawerHeaderClose {
                cursor: pointer;
                color: var(--grey-600);
            }
        }

        .drawerBody {
            flex-grow: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            a {
                padding: 16px;
                color: rgba(79, 82, 101, 1);
                cursor: pointer;
                text-decoration-line: none;

                &.active {
                    color: rgba(0, 0, 0, 1);
                    font-weight: 500;
                }
            }
        }
    }
}
