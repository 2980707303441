.deleteButton {
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 36px;
  border: 1px solid rgba(218, 220, 226, 0.81);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;

  &:hover {
    background-color: #e2626e;
    color: white;

    svg, rect, path {
      fill: white;
    }
  }

  &.enabled {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}
