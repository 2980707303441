
.otherOptionsEditor {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 12px;
}

.otherOptionsEditorRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  label {
    margin-right: 0.5rem;
  }
}
