.editableText {
  display: flex;
  cursor: pointer;
  align-items: flex-end;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #292C31;
  }

  .editIcon {
    margin-left: 5px;

  }
}
