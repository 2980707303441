.quickField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;

  &.isOver {
    background-color: #e2626e;
    color: white;
    border-radius: 12px;

    svg, rect, path {
      fill: white;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 100%;
  }

  label {
    width: 34px;
    height: 23px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;

    color: #292C31;

    margin-bottom: 2px;
  }
}
