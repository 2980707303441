@import '../index.scss';

.header {
  display: flex;
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 5px var(--grey-30);
  left: 0;
  right: 0;
  z-index: 15;
  padding: 8px 16px;

  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  .promptHeader {
    margin-left: 6px;
    margin-right: 6px;
    display: inline-block !important;
    background: #EBEDF2;
    border-radius: 6px;
    padding: 6px 8px;
    color: #6C7589;
    font-size: 14px;
    max-height: 30px;
    max-width: 800px;

    flex: 1 1 0;
    min-width: 0;

    // Prevent children divs from causing parent to expand
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      display: inline !important;
      color: #414859;
    }
  }

  .headerDivider {
    * {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;

    .left {
      display: flex;
      flex: 1;

      @include respond-to('small') {
        flex-grow: 0;
        flex-shrink: 1;
      }
    }

    .center {
      z-index: 1;
      min-width: 0;
      display: flex;
      flex: 1 1 0;
      justify-content: center;

      a {
        white-space: nowrap;
        color: rgba(79, 82, 101, 1);
        cursor: pointer;
        padding: 0 36px;
        text-decoration-line: none;

        &.active {
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      @include respond-to('small') {
        flex-grow: 0;
        flex-shrink: 1;
      }
    }
  }

  @media (max-width: 819px) {
    padding: 24px 5px;
  }

  .logo {
    cursor: pointer;

    &.logoFull {
      max-width: 200px;
      margin-right: 20px;

      @media (max-width: 819px) {
        display: none;
      }
    }

    &.logoIcon {
      display: none;
      min-width: 30px;
      height: 35px;

      @media (max-width: 819px) {
        display: block;
        margin-right: 10px;
      }
    }
  }

  .secondaryButton {
    background-color: transparent;
    color: black;
    font-weight: 500;
  }

  .saveButton {
    background-color: var(--prim-100);
  }

  .fieldContainer {
    max-width: 400px;
    flex: 1;
    height: 40px;
    margin-right: 100px;
    border-radius: 14px;
    background-color: white;
    border: 1px solid var(--grey-40);
    display: flex;
    align-items: center;

    @media (max-width: 819px) {
      margin-right: 10px;
      zoom: 0.9;
    }

    .promptInput {
      border: none;
      outline: none !important;
      flex: 1;
      height: 100%;
      padding: 0 15px;
      background-color: transparent;
      font-size: 16px;
    }

    .fieldContainerButton {
      background-color: var(--grey-10);
      border: 1px solid var(--grey-40);
      color: black;
      height: 35px;
      margin-right: 3px;
      margin-left: 0;
      font-weight: 300;
    }
  }
}
