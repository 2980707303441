@import '../../index.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Axiforma', -apple-system, BlinkMacSystemFont, sans-serif
}

.promptWrapper {
  border-radius: 15px;
  min-width: 100%;
  min-height: 100%;
  background-color: white;

  .promptContainer {
    display: flex;
    padding: 8px 16px;
    gap: 8px;
    position: relative;

    &.promptContainerColumn {
      flex-direction: column;
      padding: 16px;

      @include respond-to('small') {
        height: initial;
      }
    }

    &.pdfPrompt {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .promptInputContainer {
    position: relative;
    display: flex;
    flex: 1;
    gap: 8px;
  }

  .promptCharCount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.35);
    position: absolute;
    right: 10px;
    top: 8px;

    &.promptOverLimit {
        color: #e2626e;
    }
  }

  .promptInput {
    width: 100%;
    border: 1px solid #EAEBEE;
    border-radius: 11px;
    padding: 0 15px;
    outline: none !important;
    background-color: transparent;
    font-size: 16px;
    display: flex;
    flex: 1;

    min-height: 50px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.35);
      font-weight: bold;
    }
  }

  .optionsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //align-items: center;
    gap: 8px;

    @include respond-to('small') {
      flex-direction: column;
    }
  }

  .pdfInputContainer {
    display: flex;
    flex-direction: row;

    .pdfInput {
      margin-top: 8px;
    }
  }

  .promptGenerateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 12px 24px;

    width: 100%;
    height: 48px;

    background: #E2626E;
    border-radius: 12px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;

    color: white;

    border: none;

    cursor: pointer;
  }
}

.responsive {
    @include respond-to('small') {
        width: 100%;
    }
}
