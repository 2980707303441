// modal

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
  animation: fadeIn 0.3s ease-in-out;

  .modalBody {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 333px;
    box-sizing: border-box;
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modalHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .closeButton {
      cursor: pointer;
      font-size: 24px;
      font-weight: 600;
      color: #333849;
      background-color: #EBEDF2;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 8px;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
