
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.8);
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggleSwitchSlider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

input:checked + .toggleSwitchSlider {
  background-color: #e2626e;
}

input:checked + .toggleSwitchSlider:before {
  transform: translateX(26px);
}

