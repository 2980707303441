$secondary-color: #F4F5F8;
$primary-color: #E2626E;

.button {
    color: #000;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.2s ease-in-out;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    background: $secondary-color;
    border: 1px solid darken($secondary-color, 20%);
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:hover {
        /* darken */
        background: darken($secondary-color, 10%);
        border: 1px solid darken($secondary-color, 20%);
    }

    &.primary, &.loading {
        font-weight: 600;
        color: white;
        background: $primary-color;
        border: 1px solid $primary-color;;

        &:hover {
            /* darken */
            background: darken($primary-color, 5%);
            border: 1px solid darken($primary-color, 5%);
        }
    }
}

