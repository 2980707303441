.optionsEditor {

  margin-right: 12px;
  margin-left: 12px;

  .dragHandle {
    cursor: grab;
    margin-left: 8px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .optionContainer {
    display: flex;
    margin-bottom: 6px;
    background-color: white;
    border-radius: 6px;
    color: #5A6069;
    border: 1px solid #DBDFE8;
    padding: 6px;
    align-items: center;

    &.isOver {
      background-color: #e2626e;
      color: white;

      input {
        color: white;
      }

      svg, rect, path {
        fill: white;
      }
    }

    &.addOption {
      background: #F4F5F8;
      border: 1px dashed #BDC2CE;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #e2626e;
        color: white;

        svg, rect, path {
          fill: white;
        }
      }
    }

  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    flex: 1;
  }

  .deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
