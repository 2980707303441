.body {
  position: absolute; /* absolute positioning so it can scroll independently */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto; /* add scrollbars as necessary */

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 60px);
    &.verticalCenter {
      justify-content: center;
    }
  }
}

.background {
  position: relative;  /* create a new stacking context */
  height: 100vh; /* 100% of the viewport's height */
  width: 100vw; /* 100% of the viewport's width */
  background: #F4F5F8;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;  /* prevent the content div from overflowing */

  &.gradient {
    background-image: url('components/gradient.svg');
    background-size: cover;
  }
}
.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 60px;
  background-color: white;
  box-shadow: 0 2px 5px var(--grey-30);
  left: 0;
  right: 0;
  z-index: 15;
}
