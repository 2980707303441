@import '../../index.scss';

.stepContainer {

  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(22, 27, 47, 0.12);
  border-radius: 16px;

  padding: 25px;

  @include respond-to('small') {
    padding: 8px;
  }


  transition: all 0.2s ease-in-out;

  .questionWrapper {
    border: 1px solid rgba(218, 220, 226, 0.81);
    border-radius: 12px;
  }

  .pageTitle {
    display: flex;
    align-items: center;
    height: 36px;

    input {
      padding: 6px;
      font-size: 16px;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}
