@import '../../index.scss';

.pageWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  width: 100%;
  padding: 32px 0;

  @include respond-to('small') {
    padding: 8px 0;
  }
}

.title {
  font-size: 52px;
  font-weight: 700;
  max-width: 500px;
  text-align: center;
  line-height: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  user-select: none;

  div {
    display: flex;
    padding: 10px 35px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 819px) {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 10px;
  }
}

.description {
  font-size: 18px;
  color: var(--grey-90);
  line-height: 30px;
  max-width: 700px;
  text-align: center;
  margin-bottom: 40px;
  user-select: none;

  @media (max-width: 819px) {
    font-size: 16px;
  }
}

.promptContainer {
  box-sizing: border-box;

  width: 729px;

  background-color: rgba(8,17,47,.07);
  border-radius: 20px;
  box-shadow: 0 22px 44px -12px rgba(25,47,89,.38);
  padding: 9px;

  margin-bottom: 50px;

  @include respond-to('small') {
    width: 100%;
  }
}

.fieldContainerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .fieldContainer {
    flex-grow: 1;
    max-width: 600px;
    height: 55px;
    border-radius: 14px;
    background-color: white;
    border: 1px solid #EAEBEE;
    outline: 6px solid #33384910;
    display: flex;
    align-items: center;
    margin-bottom: 140px;

    @media (max-width: 819px) {
      margin-bottom: 0;
    }

    .promptInput {
      border: none;
      outline: none !important;
      flex: 1;
      height: 100%;
      padding: 0 15px;
      background-color: transparent;
      font-size: 16px;
    }

    .fieldContainerButton {
      background-color: var(--prim-100);
      height: 43px;
      margin-right: 7px;
      margin-left: 0;
    }
  }
}

.examplePromptContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  .exampleHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    user-select: none;

    svg {
        margin-right: 10px;
    }
  }

  .examplePrompt {
    width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-90);
    margin-left: 10px;
    background-color: white;
    border-radius: 11px;
    border: 1px solid #EAEBEE;
    padding: 10px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #F7F8FA;
    }
  }
}

.formWrapper {
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(22, 27, 47, 0.12);
  border-radius: 24px;
  margin: 32px 0;
  width: 100%;
  max-width: 500px;
  min-width: 512px;

  @include respond-to('small') {
    min-width: 100%;
  }
}
