.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  user-select: none;
  border: 1px solid rgba(218, 220, 226, 0.81);
  border-radius: 11px;

  &.disabled {
    background-color: #F4F5F8;
    .container {
        cursor: not-allowed;
    }
  }

  .container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 6px;

    .label {
      font-weight: 500;
      background-color: #F4F5F8;
      padding: 6px 10px;
      border-radius: 10px;
      white-space: nowrap;
    }

    .currentValue {
      font-size: 16px;
      font-weight: 400;
    }

    svg {
      margin-right: 8px;
    }
  }

  .options {
    margin-top: 1px;
    overflow: hidden;
    position: absolute;
    min-width: 160px;
    max-height: 300px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 99;
    cursor: pointer;
    width: 100%;
    border-radius: 12px;

    .optionsContainer {
      max-height: 300px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
    }

    &.open {
        opacity: 1;
    }

    &.up {
      top: auto;
      bottom: 100%;
      background-color: rgba(250,250,250);;
    }

    &.down {
      bottom: auto;
      top: 100%;
      background-color: rgba(250,250,250);
    }

    .option {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      border-radius: 12px;
    }

    .option:hover {
      background-color: #f1f1f1;
    }
  }
}
