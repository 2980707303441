:root {
  font-family: 'Axiforma', -apple-system, BlinkMacSystemFont, sans-serif !important;

  --main-nav-height: 55px;
  --editor-sidebar-width: 350px;
  --selection-panel-width: 210px;
  --editor-connections-sidebar-width: 500px;

  --text-light: #888888;
  --button-primary-hover: #cb4e5a;
  --button-neutral-hover: #fbfdff;
  --button-negative-hover: #d13232;

  --bg: #f5f5f5;
  --bg-dark: #eee;
  --bg-light: #fff;

  --red: #ff5e63;
  --gray: #666666;
  --green: #28a745;

  --grey-border: #dee2e6;
  --green-border: #0ebe74;
  --red-border: #e84343;

  --grey-90: #333849;
  --grey-80: #414859;
  --grey-70: #6c7589;
  --grey-60: #a3aaba;
  --grey-50: #bdc2ce;
  --grey-40: #cfd4dd;
  --grey-30: #dbdfe8;
  --grey-20: #ebeff2;
  --grey-10: #f4f5f8;

  --prim-100: #e2626e;
  --prim-80: #efa7ad;
  --prim-60: #eda3a1;
  --prim-40: #f2bab9;
  --prim-20: #f5cbcb;
  --prim-10: #fbeaea;

  // Override Bootstrap disabled background color for buttons
  --bs-btn-disabled-bg: var(--prim-100);

  --error: #d6504a;
  --validation-pass-light: #f8fefb;
  --validation-error-light: #fff3f4;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.featheryButton {
  border: none;
  background: black;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 10px;
  font-size: 14px;
  color: white;
  font-family: inherit;
  cursor: pointer;
  font-weight: 600;
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 100;
  src: local('Axiforma'), url('fonts/AxiformaThinfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 200;
  src: local('Axiforma'),
  url('fonts/AxiformaLightfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 300;
  src: local('Axiforma'), url('fonts/AxiformaBookfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 400;
  src: local('Axiforma'),
  url('fonts/AxiformaRegularfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 500;
  src: local('Axiforma'),
  url('fonts/AxiformaMediumfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 600;
  src: local('Axiforma'),
  url('fonts/AxiformaSemiBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 700;
  src: local('Axiforma'), url('fonts/AxiformaBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 800;
  src: local('Axiforma'),
  url('fonts/AxiformaExtraBoldfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 900;
  src: local('Axiforma'),
  url('fonts/AxiformaBlackfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Axiforma';
  font-weight: 1000;
  src: local('Axiforma'),
  url('fonts/AxiformaHeavyfont.woff2') format('woff2');
}

// Define breakpoints
$breakpoints: (
  'small': 768px,
);

// Mixin for media queries
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}
