.textInput {
  border: 1px solid rgba(218, 220, 226, 0.81);
  border-radius: 14px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
  font-size: 16px;
  outline: none;
  user-select: all;
}
