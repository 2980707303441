.loaderContainer {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 700px;
    padding-bottom: 70px;
  }
}
